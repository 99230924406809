// src/utils/logger.js
const DEBUG = process.env.REACT_APP_DEBUG_MODE === 'true';

export const logger = {
  log: (message, data) => {
    if (DEBUG) {
      console.log(`[LOG] ${message}`, data);
    }
  },
  warn: (message, data) => {
    if (DEBUG) {
      console.warn(`[WARN] ${message}`, data);
    }
  },
  error: (message, error) => {
    if (DEBUG) {
      console.error(`[ERROR] ${message}`, error);
    }
  }
};