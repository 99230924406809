import React from 'react';

function AdminDashboard() {
  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-2xl font-semibold mb-4">Welcome to the Admin Dashboard</h2>
      <p className="text-gray-600">
        This is your central hub for managing the referral system. Use the sidebar to navigate to different sections and manage various aspects of the system.
      </p>
      {/* Add more content or quick links here as needed */}
    </div>
  );
}

export default AdminDashboard;