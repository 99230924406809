import React, { useState, useEffect, useCallback } from 'react';
import payPlansService from '../../../api/payPlansService.js';
import PayPlanList from './PayPlanList';
import PayPlanForm from './PayPlanForm';
import CommissionList from './ComissionList.js';
import { useAlert } from '../../../hooks/useAlert.js';

function Payplans() {
  const [payPlans, setPayPlans] = useState([]);
  const [selectedPayPlan, setSelectedPayPlan] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  const [commissions, setCommissions] = useState([]);

  const { showAlert} = useAlert();

  const [selectedCommission, setSelectedCommission] = useState(null);




  const fetchPayPlans = useCallback(async () => {
    try {
      const data = await payPlansService.getPayPlans();
      setPayPlans(data);
    } catch (error) {
      showAlert('error', 'Error fetching pay plans. Please try again.');
    }
  }, [showAlert]);

  const fetchCommissions = useCallback(async (payPlanId) => {
    try {
      const data = await payPlansService.getCommissions(payPlanId);
      setCommissions(data);
    } catch (error) {
      showAlert('error', 'Error fetching commissions. Please try again.');
    }
  }, [showAlert]);

  useEffect(() => {
    fetchPayPlans();
  }, [fetchPayPlans]);

  useEffect(() => {
    if (selectedPayPlan) {
      fetchCommissions(selectedPayPlan.id);
    } else {
      setCommissions([]);
    }
  }, [selectedPayPlan, fetchCommissions]);

  const handlePayPlanSelect = useCallback((payPlan) => {
    setSelectedPayPlan(payPlan);
    setIsCreating(false);
  }, []);

  const handleCreateNew = useCallback(() => {
    setSelectedPayPlan(null);
    setIsCreating(true);
    setCommissions([]);
  }, []);



  const handlePayPlanSubmit = useCallback(async (payPlanData) => {
    try {
      let updatedPayPlan;
      if (isCreating) {
        updatedPayPlan = await payPlansService.createPayPlan(payPlanData);
        setPayPlans(prevPayPlans => [...prevPayPlans, updatedPayPlan]);
        showAlert('success', 'Pay plan created successfully');
      } else {
        updatedPayPlan = await payPlansService.updatePayPlan(selectedPayPlan.id, payPlanData);
        setPayPlans(prevPayPlans => prevPayPlans.map(plan => plan.id === updatedPayPlan.id ? updatedPayPlan : plan));
        showAlert('success', 'Pay plan updated successfully');
      }
      setSelectedPayPlan(updatedPayPlan);
      setIsCreating(false);
    } catch (error) {
      showAlert('error', `Error ${isCreating ? 'creating' : 'updating'} pay plan. Please try again.`);
    }
  }, [isCreating, selectedPayPlan,showAlert]);

  const handleCommissionUpdate = useCallback((updatedCommission) => {
    setCommissions(prevCommissions => 
      prevCommissions.map(c => 
        c.id === updatedCommission.id ? { ...updatedCommission, isUpdated: true } : c
      )
    );
    
  }, [selectedPayPlan]);

  const handlePayPlanDelete = async (payPlanId) => {
    try {
      await payPlansService.deletePayPlan(payPlanId);
      setPayPlans(prevPayPlans => prevPayPlans.filter(plan => plan.id !== payPlanId));
      handleCreateNew();
      showAlert('success', 'Pay plan deleted successfully');
    } catch (error) {
      showAlert('error', 'Error deleting pay plan. Please try again.');
    }
  };

  const handleCommissionCreate = useCallback((newCommission) => {
    setCommissions(prevCommissions => [...prevCommissions, { ...newCommission, isNew: true }]);
  }, []);

  const handleCommissionDelete = useCallback(async (commissionId) => {
    try {
      await payPlansService.deleteCommission(selectedPayPlan.id, commissionId);
      setCommissions(prevCommissions => prevCommissions.filter(c => c.id !== commissionId));
      showAlert('success', 'Commission deleted successfully');
    } catch (error) {
      showAlert('error', 'Error deleting commission. Please try again.');
    }
  }, [selectedPayPlan, showAlert]);

  return (
    <div className="flex h-full bg-gray-100">
      <div className="w-64 p-4">
        <PayPlanList
          payPlans={payPlans}
          onSelect={handlePayPlanSelect}
          selectedId={selectedPayPlan?.id}
          onCreateNew={handleCreateNew}
        />
      </div>
      <div className="flex-1 p-4">
        <div className="bg-white rounded-lg shadow-md p-6">
          {isCreating ? (
            <PayPlanForm 
              onSubmit={handlePayPlanSubmit}
              isCreating={true}
            />
          ) : selectedPayPlan ? (
            <div className="space-y-6">
              <PayPlanForm 
                payPlan={selectedPayPlan}
                onSubmit={handlePayPlanSubmit}
                isCreating={false}
                onDelete={handlePayPlanDelete}
              />
              <CommissionList
                key = {commissions.map(c => c.id).join(',')}
                commissions={commissions}
                onUpdate={handleCommissionUpdate}
                onCreate={handleCommissionCreate}
                onDelete={handleCommissionDelete}
                payPlanId={selectedPayPlan.id}
                showAlert={showAlert}
              />
            </div>
          ) : (
            <p className="text-center text-gray-500 mt-10">Select a pay plan or create a new one to get started.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Payplans;