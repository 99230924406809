import React, { useState, useEffect } from 'react';
import { Search, Download, ChevronLeft, ChevronRight } from 'lucide-react';
import earningsService from '../../api/transactionsService';
import { useAlert } from '../../hooks/useAlert';

const TransactionCard = ({ title, amount, count, color }) => (
  <div className="bg-white rounded-lg shadow-md p-4">
    <h3 className="text-gray-600 text-sm font-medium">{title}</h3>
    <div className={`text-2xl font-bold ${color} mt-2`}>
      ${amount.toFixed(2)}
    </div>
    <div className="text-gray-500 text-sm mt-1">
      {count} transactions
    </div>
  </div>
);

const MemberTransactions = () => {
  const { showAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [earnings, setEarnings] = useState([]);
  const [summary, setSummary] = useState({
    total: { amount: 0, count: 0 },
    available: { amount: 0, count: 0 },
    withdrawn: { amount: 0, count: 0 },
    paid: { amount: 0, count: 0 }
  });
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    status: '',
    commissionId: ''
  });
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0
  });
  const [availableCommissions, setAvailableCommissions] = useState([]);
  const [selectedEarnings, setSelectedEarnings] = useState([]);

  const fetchEarnings = async () => {
    setIsLoading(true);
    try {
      const data = await earningsService.getMemberEarnings(
        filters,
        pagination.pageNumber,
        pagination.pageSize
      );
      setEarnings(data.earnings);
      setSummary(data.summary);
      setPagination(data.metadata);
      setAvailableCommissions(data.filters.availableCommissions);
    } catch (error) {
      showAlert('error', error.message || 'Failed to fetch earnings');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEarnings();
  }, [pagination.pageNumber, filters]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
    setPagination(prev => ({ ...prev, pageNumber: 1 }));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    fetchEarnings();
  };

  const handlePageChange = (newPage) => {
    setPagination(prev => ({ ...prev, pageNumber: newPage }));
  };

  const handleEarningSelection = (earningId) => {
    setSelectedEarnings(prev => {
      if (prev.includes(earningId)) {
        return prev.filter(id => id !== earningId);
      }
      return [...prev, earningId];
    });
  };

  const handleWithdrawSelected = async () => {
    if (selectedEarnings.length === 0) {
      showAlert('warning', 'Please select earnings to withdraw');
      return;
    }

    try {
      await earningsService.withdrawEarnings(selectedEarnings);
      showAlert('success', 'Withdrawal request submitted successfully');
      setSelectedEarnings([]);
      fetchEarnings();
    } catch (error) {
      showAlert('error', error.message || 'Failed to submit withdrawal request');
    }
  };

  const getStatusStyle = (status) => {
    const styles = {
      AVAILABLE: 'bg-green-100 text-green-800',
      WITHDRAWN: 'bg-blue-100 text-blue-800',
      PAID: 'bg-purple-100 text-purple-800',
      CANCELLED: 'bg-red-100 text-red-800'
    };
    return styles[status] || 'bg-gray-100 text-gray-800';
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 min-h-screen p-6">
      <div className="max-w-7xl mx-auto space-y-6">
        {/* Header */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <h1 className="text-2xl font-bold text-gray-900">My Earnings</h1>
          <p className="text-gray-600 mt-1">Track and manage your earned commissions</p>
        </div>

        {/* Summary Cards */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <TransactionCard
            title="Total Earnings"
            amount={summary.total.amount}
            count={summary.total.count}
            color="text-gray-900"
          />
          <TransactionCard
            title="Available"
            amount={summary.available.amount}
            count={summary.available.count}
            color="text-green-600"
          />
          <TransactionCard
            title="Withdrawn"
            amount={summary.withdrawn.amount}
            count={summary.withdrawn.count}
            color="text-blue-600"
          />
          <TransactionCard
            title="Paid"
            amount={summary.paid.amount}
            count={summary.paid.count}
            color="text-purple-600"
          />
        </div>

        {/* Filters */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <form onSubmit={handleSearch} className="grid grid-cols-1 md:grid-cols-5 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Start Date
              </label>
              <input
                type="date"
                name="startDate"
                value={filters.startDate}
                onChange={handleFilterChange}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                End Date
              </label>
              <input
                type="date"
                name="endDate"
                value={filters.endDate}
                onChange={handleFilterChange}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Status
              </label>
              <select
                name="status"
                value={filters.status}
                onChange={handleFilterChange}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="">All Statuses</option>
                <option value="AVAILABLE">Available</option>
                <option value="WITHDRAWN">Withdrawn</option>
                <option value="PAID">Paid</option>
                <option value="CANCELLED">Cancelled</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Commission Type
              </label>
              <select
                name="commissionId"
                value={filters.commissionId}
                onChange={handleFilterChange}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="">All Commissions</option>
                {availableCommissions.map(commission => (
                  <option key={commission.id} value={commission.id}>
                    {commission.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-end">
              <button
                type="submit"
                className="w-full bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Apply Filters
              </button>
            </div>
          </form>
        </div>

        {/* Withdraw Button */}
        {summary.available.amount > 0 && (
          <div className="flex justify-end">
            <button
              onClick={handleWithdrawSelected}
              disabled={selectedEarnings.length === 0}
              className={`${
                selectedEarnings.length > 0
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-400 cursor-not-allowed'
              } text-white px-6 py-2 rounded-md transition-colors duration-200 flex items-center space-x-2`}
            >
              <Download size={18} />
              <span>Withdraw Selected ({selectedEarnings.length})</span>
            </button>
          </div>
        )}

        {/* Transactions Table */}
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        const availableEarnings = earnings
                          .filter(earning => earning.status === 'AVAILABLE')
                          .map(earning => earning.id);
                        setSelectedEarnings(
                          e.target.checked ? availableEarnings : []
                        );
                      }}
                      checked={
                        selectedEarnings.length > 0 &&
                        selectedEarnings.length === earnings.filter(e => e.status === 'AVAILABLE').length
                      }
                    />
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Commission
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    From
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Amount
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {earnings.map((earning) => (
                  <tr key={earning.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      {earning.status === 'AVAILABLE' && (
                        <input
                          type="checkbox"
                          checked={selectedEarnings.includes(earning.id)}
                          onChange={() => handleEarningSelection(earning.id)}
                        />
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(earning.scheduledDate)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {earning.commission.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {earning.payer.username}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      ${earning.amount.toFixed(2)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusStyle(earning.status)}`}>
                        {earning.status}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{' '}
                  <span className="font-medium">
                    {(pagination.pageNumber - 1) * pagination.pageSize + 1}
                  </span>{' '}
                  to{' '}
                  <span className="font-medium">
                    {Math.min(pagination.pageNumber * pagination.pageSize, pagination.totalCount)}
                  </span>{' '}
                  of{' '}
                  <span className="font-medium">{pagination.totalCount}</span>{' '}
                  results
                </p>
              </div>
              <div>
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                  <button
                    onClick={() => handlePageChange(pagination.pageNumber - 1)}
                    disabled={!pagination.hasPreviousPage}
                    className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
                  >
                    <ChevronLeft className="h-5 w-5" />
                    Previous
                  </button>
                  <button
                    onClick={() => handlePageChange(pagination.pageNumber + 1)}
                    disabled={!pagination.hasNextPage}
                    className="relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
                  >
                    Next
                    <ChevronRight className="h-5 w-5" />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );


}

export default MemberTransactions