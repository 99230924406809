import React, { useState, useEffect } from 'react';
import CycleManagement from './CycleManagement';
import generalSettingsService from '../../../api/generalSettingsService';
import { useAlert } from '../../../hooks/useAlert';
import { useConfirmationModal } from '../../../hooks/useConfirmation';
import { Save } from 'lucide-react';

export default function GeneralSettings() {
  const [settings, setSettings] = useState({
    siteTitle: '',
    siteName: '',
    siteLogo: '',
    senderEmail: '',
    fromName: '',
    enableRegistration: true,
    mandatoryReferral: true,
    allowDuplicateEmail: false
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  
  const { showAlert } = useAlert();
  const { openModal } = useConfirmationModal();

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      setLoading(true);
      const response = await generalSettingsService.getSettings();
      setSettings(response.data);
    } catch (err) {
      showAlert('error', 'Failed to load settings');
      console.error('Error loading settings:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    openModal(
      'Confirm Settings Update',
      'Are you sure you want to update the system settings? This will affect the entire application.',
      async () => {
        try {
          setSaving(true);
          await generalSettingsService.updateSettings(settings);
          showAlert('success', 'Settings updated successfully');
        } catch (err) {
          showAlert('error', 'Failed to update settings');
          console.error('Error updating settings:', err);
        } finally {
          setSaving(false);
        }
      }
    );
  };

  if (loading) {
    return (
      <div className="bg-white shadow rounded-lg p-6 max-w-4xl mx-auto">
        <div className="animate-pulse">
          <div className="h-4 bg-gray-200 rounded w-1/4 mb-4"></div>
          <div className="h-4 bg-gray-200 rounded w-1/2 mb-4"></div>
          <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="bg-white shadow rounded-lg p-6 max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">System Settings</h2>
          <button
            onClick={handleSubmit}
            disabled={saving}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-300 flex items-center"
          >
            <Save size={18} className="mr-2" />
            {saving ? 'Saving...' : 'Save Settings'}
          </button>
        </div>
        
        <form className="space-y-6">
          {/* Site Information */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium border-b pb-2">Site Information</h3>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Site Title</label>
                <input
                  type="text"
                  name="siteTitle"
                  value={settings.siteTitle}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Site Name</label>
                <input
                  type="text"
                  name="siteName"
                  value={settings.siteName}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>

              <div className="md:col-span-2">
                <label className="block text-sm font-medium text-gray-700">Site Logo URL</label>
                <input
                  type="text"
                  name="siteLogo"
                  value={settings.siteLogo || ''}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
            </div>
          </div>

          {/* Email Settings */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium border-b pb-2">Email Settings</h3>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Sender Email</label>
                <input
                  type="email"
                  name="senderEmail"
                  value={settings.senderEmail}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">From Name</label>
                <input
                  type="text"
                  name="fromName"
                  value={settings.fromName}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
            </div>
          </div>

          {/* Registration Settings */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium border-b pb-2">Registration Settings</h3>
            
            <div className="space-y-3">
              <div className="flex items-center bg-gray-100 p-2 rounded">
                <input
                  type="checkbox"
                  name="enableRegistration"
                  checked={settings.enableRegistration}
                  onChange={handleChange}
                  className="h-4 w-4 text-blue-600 rounded border-gray-300"
                />
                <label className="ml-2 block text-sm text-gray-700">
                  Enable Registration
                </label>
              </div>

              <div className="flex items-center bg-gray-100 p-2 rounded">
                <input
                  type="checkbox"
                  name="mandatoryReferral"
                  checked={settings.mandatoryReferral}
                  onChange={handleChange}
                  className="h-4 w-4 text-blue-600 rounded border-gray-300"
                />
                <label className="ml-2 block text-sm text-gray-700">
                  Mandatory Referral Link
                </label>
              </div>

              <div className="flex items-center bg-gray-100 p-2 rounded">
                <input
                  type="checkbox"
                  name="allowDuplicateEmail"
                  checked={settings.allowDuplicateEmail}
                  onChange={handleChange}
                  className="h-4 w-4 text-blue-600 rounded border-gray-300"
                />
                <label className="ml-2 block text-sm text-gray-700">
                  Allow Duplicate Email
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>

      <CycleManagement />
    </div>
  );
}