import React, { createContext, useContext, useState, useCallback } from 'react';
import ConfirmationModal from '../components/common/Confirmation';

// Context
export const ConfirmationModalContext = createContext(null);

// Provider
export const ConfirmationModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState({ isOpen: false, title: '', message: '', onConfirm: null });

  const openModal = useCallback((title, message, onConfirm) => {
    setModalState({ isOpen: true, title, message, onConfirm });
  }, []);

  const closeModal = useCallback(() => {
    setModalState({ isOpen: false, title: '', message: '', onConfirm: null });
  }, []);

  return (
    <ConfirmationModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <ConfirmationModal
        isOpen={modalState.isOpen}
        title={modalState.title}
        message={modalState.message}
        onConfirm={() => {
          modalState.onConfirm();
          closeModal();
        }}
        onCancel={closeModal}
      />
    </ConfirmationModalContext.Provider>
  );
};