// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import authService from '../api/auth';
import { logger } from '../utils/logger';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ children, adminOnly }) => {
  const { admin, member } = useSelector(state => state.auth);
  const location = useLocation();

  logger.log('ProtectedRoute check', { adminOnly, isAdminAuth: admin.isAuthenticated, isMemberAuth: member.isAuthenticated, path: location.pathname });

  if (adminOnly && !admin.isAuthenticated) {
    logger.warn('Unauthorized access attempt to admin route', { path: location.pathname });
    return <Navigate to="/admin-login" state={{ from: location }} replace />;
  }

  if (!adminOnly && !member.isAuthenticated) {
    logger.warn('Unauthorized access attempt to member route', { path: location.pathname });
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;