import React from 'react';

function Referrals() {
  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-2xl font-semibold mb-4">Your Referrals</h2>
      <p className="text-gray-600 mb-4">
        Here you can view and manage your referrals. Invite more people to join and increase your network!
      </p>
      {/* Placeholder for referral list */}
      <div className="border rounded-lg p-4 bg-gray-50">
        <p className="text-gray-500 italic">Referral list will be displayed here.</p>
      </div>
      {/* Placeholder for referral link */}
      <div className="mt-4">
        <h3 className="text-lg font-semibold mb-2">Your Referral Link</h3>
        <div className="flex items-center space-x-2">
          <input 
            type="text" 
            value="https://example.com/ref/yourcode" 
            readOnly 
            className="flex-grow p-2 border rounded"
          />
          <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
            Copy
          </button>
        </div>
      </div>
    </div>
  );
}

export default Referrals;