import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const RockNRollLoader = () => {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowLoader(true), 500);
    return () => clearTimeout(timer);
  }, []);

  if (!showLoader) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="fixed inset-0 flex items-center justify-center bg-gradient-to-br from-purple-900 to-indigo-900"
    >
      <div className="text-center">
        <svg className="w-32 h-32 mx-auto mb-4" viewBox="0 0 100 100">
          {/* Vinyl record */}
          <motion.circle
            cx="50"
            cy="50"
            r="45"
            fill="none"
            stroke="#fff"
            strokeWidth="2"
            initial={{ pathLength: 0, rotate: 0 }}
            animate={{ pathLength: 1, rotate: 360 }}
            transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
          />
       
        </svg>
        <motion.h2
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="text-2xl font-bold text-white mb-2"
        >
          Loading
        </motion.h2>
     
      </div>
    </motion.div>
  );
};

export default RockNRollLoader;