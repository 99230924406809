import { axiosInstance } from './config/axiosConfig';
import { TokenManager } from './utils/tokenManager';
import { setLoading, setError, setMember, setAdmin, clearError, clearMember, clearAdmin } from '../store/authSlice';
import { logger } from '../utils/logger';
import { store } from '../store';

const authService = {
  async login(username, password) {
    store.dispatch(setLoading(true));

    try {
      store.dispatch(clearError('member'));
      TokenManager.clearToken('member');
      logger.log('Attempting member login', { username });

      const response = await axiosInstance.post('/auth/login',
        { username, password }
      );

      if (response.data.token && response.data.user) {
        TokenManager.setStoredToken('member', response.data.token);
        store.dispatch(setMember({
          user: response.data.user,
          token: response.data.token
        }));

        logger.log('Member login successful', {
          userId: response.data.user.id
        });
      }

      return response.data;
    } catch (error) {
      logger.error('Member login error', error);

      const errorMessage = error.response?.data?.message ||
        error.message ||
        (!error.response ? 'Server does not respond' : 'An error occurred during login');

      // Store error in Redux with type specification
      store.dispatch(setError({
        type: 'member',
        message: errorMessage
      }));

      throw error;
    } finally {
      store.dispatch(setLoading(false));
    }
  },

  async adminLogin(username, password) {
    store.dispatch(setLoading(true));

    try {
      store.dispatch(clearError('admin'));
      TokenManager.clearToken('admin');
      logger.log('Attempting admin login', { username });

      const response = await axiosInstance.post('/auth/admin-login',
        { username, password }
      );

      if (response.data.token) {
        TokenManager.setStoredToken('admin', response.data.token);
        TokenManager.setRole(response.data.role || 'MEMBER');

        store.dispatch(setAdmin({
          user: {
            ...response.data.user,
            role: response.data.role
          },
          token: response.data.token
        }));

        logger.log('Admin login successful', {
          role: response.data.role
        });
      }

      return response.data;
    } catch (error) {
      logger.error('Admin login error', error);

      const errorMessage = error.response?.data?.message ||
        error.message ||
        (!error.response ? 'Server does not respond' : 'An error occurred during admin login');

      // Store error in Redux with type specification
      store.dispatch(setError({
        type: 'admin',
        message: errorMessage
      }));

      throw error;
    } finally {
      store.dispatch(setLoading(false));
    }
  },

  async logout(isAdmin = false) {
    const currentPath = window.location.pathname;

    logger.log('Logout initiated', {
      currentPath,
      isAdmin
    });

    TokenManager.clearToken(isAdmin ? 'admin' : 'member');
    store.dispatch(isAdmin ? clearAdmin() : clearMember());

    const loginPath = isAdmin ? '/admin-login' : '/login';
    logger.log('Redirecting to', { loginPath });
    window.location.href = loginPath;
  },

  async initializeAuth(currentPath) {
    const memberToken = TokenManager.getStoredToken('member');
    const adminToken = TokenManager.getStoredToken('admin');

    logger.log('Initializing auth', {
      hasMemberToken: !!memberToken,
      hasAdminToken: !!adminToken,
      currentPath
    });

    const isAdminRoute = currentPath.startsWith('/admin');
    const isMemberRoute = currentPath.startsWith('/member') || currentPath === '/register';

    try {
      if (isAdminRoute && adminToken && !TokenManager.isTokenExpired(adminToken, 'admin')) {
        const response = await axiosInstance.get('/members/current-user-admin',
          { useAdminToken: true }
        );

        store.dispatch(setAdmin({
          user: {
            ...response.data,
            role: response.data.role || 'MEMBER'
          },
          token: adminToken
        }));

        logger.log('Admin auth initialized', {
          role: response.data.role
        });
      }
      else if (isMemberRoute && memberToken && !TokenManager.isTokenExpired(memberToken, 'member')) {
        const response = await axiosInstance.get('/members/current-user',
          { useMemberToken: true }
        );

        store.dispatch(setMember({
          user: response.data,
          token: memberToken
        }));

        logger.log('Member auth initialized', {
          userId: response.data.id
        });
      }
      else if (!currentPath.includes('login') && !currentPath.includes('register')) {
        logger.log('No valid token found, redirecting to login');
        await this.logout(isAdminRoute);
      }
    } catch (error) {
      logger.error('Error during auth initialization', error);
      store.dispatch(setError({
        type: isAdminRoute ? 'admin' : 'member',
        message: 'Session expired. Please login again.'
      }));

      await this.logout(isAdminRoute);
    }
  },

  async register(userData) {
    store.dispatch(clearError('member')); // Clear any existing errors before registration
    try {
      return await axiosInstance.post('/auth/register', userData);
    } catch (error) {
      const errorMessage = error.response?.data?.message ||
        error.message ||
        'Registration failed. Please try again.';

      store.dispatch(setError({
        type: 'member',
        message: errorMessage
      }));

      throw error;
    }
  }
};

export default authService;