import React, { useState, useCallback } from 'react';
import CommissionForm from './ComissionForm.js';
import { Edit, Trash2, Plus } from 'lucide-react';
import { useConfirmationModal } from '../../../hooks/useConfirmation.js';

function CommissionList({ commissions, onUpdate, onCreate, onDelete, payPlanId, showAlert }) {
  const [editingCommissionId, setEditingCommissionId] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const { openModal } = useConfirmationModal();

  const handleSubmit = useCallback((commission, isCreating) => {
    if (isCreating) {
      onUpdate(commission);
    } else {
      onCreate(commission);
    }
    setEditingCommissionId(null);
    setIsCreating(false);
  }, [onCreate, onUpdate]);

  const handleCancel = () => {
    setEditingCommissionId(null);
    setIsCreating(false);
  };


  const handleDeleteClick = (commissionId) => {
    openModal (
      'Confirm Deletion',
      'Are you sure you want to delete this commission?',
      () => onDelete(commissionId)
    );

  };

  const renderModal = () => {
    const isEditing = editingCommissionId !== null;
    const commission = isEditing ? commissions.find(c => c.id === editingCommissionId) : null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full z-50" id="my-modal">
        <div className="relative top-20 mx-auto p-5 border w-11/12 max-w-4xl shadow-lg rounded-md bg-white">
        {(isCreating || editingCommissionId !== null) && (
        <CommissionForm
          commission={isCreating ? null : commissions.find(c => c.id === editingCommissionId)}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          payPlanId={payPlanId}
          showAlert={showAlert}
        />
      )}
        </div>
      </div>
    );
  };

  return (
    <div className="mt-8 bg-white shadow-md rounded-lg overflow-hidden">
      <div className="flex justify-between items-center px-6 py-4 bg-gray-50 border-b border-gray-200">
        <h3 className="text-xl font-semibold text-gray-800">Commissions</h3>
        <button
          onClick={() => setIsCreating(true)}
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-300"
        >
          <Plus size={20} className="mr-2" />
          Add Commission
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Depth</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cycle</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Periods</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Self-Pay %</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Pro Option</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {commissions.filter(c => !c.isDeleted).map((commission) => (
              <tr key={commission.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{commission.name}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{commission.depth}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {commission.cycle ? commission.cycle.name : 'N/A'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {commission.cycle && commission.cycle.periodType !== 'ONE_TIME'
                    ? commission.numberOfPeriods
                    : 'N/A'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{commission.selfPayPercentage}%</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {commission.proOption ? 'Yes' : 'No'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button
                    onClick={() => setEditingCommissionId(commission.id)}
                    className="text-indigo-600 hover:text-indigo-900 mr-3"
                  >
                    <Edit size={18} />
                  </button>
                  <button
                 onClick={() => handleDeleteClick(commission.id)}
                    className="text-red-600 hover:text-red-900"
                  >
                    <Trash2 size={18} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {(isCreating || editingCommissionId !== null) && renderModal()}
    </div>
  );
}

export default CommissionList;