// src/api/utils/TokenManager.js
import { store } from '../../store';
import { clearMember, clearAdmin } from '../../store/authSlice';
import { logger } from '../../utils/logger';

export const REFRESH_THRESHOLD = 5 * 60; // 5 minutes in seconds

export class TokenManager {
  static parseToken(token) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (error) {
      logger.error('Error parsing token', error);
      return null;
    }
  }

  static isTokenExpired(token, tokenType) {
    if (!token) {
      logger.warn('Token is null or undefined');
      return true;
    }

    try {
      const decoded = this.parseToken(token);
      if (!decoded) return true;

      const currentTime = Math.floor(Date.now() / 1000);
      const expired = currentTime >= decoded.exp;
      
      logger.log(`Token expiration check`, { 
        expired, 
        expiry: decoded.exp,
        timeRemaining: decoded.exp - currentTime,
        tokenType 
      });

      if (expired) {
        this.clearToken(tokenType);
      }

      return expired;
    } catch (error) {
      logger.error('Error checking token expiration', error);
      this.clearToken(tokenType);
      return true;
    }
  }

  static willExpireSoon(token) {
    if (!token) return true;
    
    const decoded = this.parseToken(token);
    if (!decoded) return true;
    
    const currentTime = Math.floor(Date.now() / 1000);
    return (decoded.exp - currentTime) < REFRESH_THRESHOLD;
  }

  static clearToken(tokenType) {
    if (tokenType === 'admin') {
      store.dispatch(clearAdmin());
      localStorage.removeItem('adminToken');
      localStorage.removeItem('role');
    } else if (tokenType === 'member') {
      store.dispatch(clearMember());
      localStorage.removeItem('memberToken');
    }
  }

  static getStoredToken(tokenType) {
    return localStorage.getItem(`${tokenType}Token`);
  }

  static setStoredToken(tokenType, token) {
    localStorage.setItem(`${tokenType}Token`, token);
  }

  static getRole() {
    return localStorage.getItem('role');
  }

  static setRole(role) {
    localStorage.setItem('role', role);
  }
}