import React, { useState, useEffect } from 'react';
import { X, User, Phone, MapPin, Mail, Globe, Trash2, Facebook, Twitter, Linkedin, Instagram, Lock } from 'lucide-react';
import memberService from '../../../api/memberService';
import { useAlert } from '../../../hooks/useAlert';
import { createPersonalInfo, mergePersonalInfo, updatePersonalInfo } from '../../../models/personalInfo';


const InputField = ({ icon: Icon, label, ...props }) => (
  <div className="relative">
    {Icon && (
      <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
        <Icon className="h-5 w-5" />
      </div>
    )}
    <input
      className={`w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${
        Icon ? 'pl-10' : ''
      } peer placeholder-transparent`}
      placeholder={label}
      {...props}
    />
    <label
      className={`absolute left-3 -top-2.5 bg-white px-1 text-sm text-gray-600 transition-all 
                  peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 
                  peer-placeholder-shown:top-2 peer-focus:-top-2.5 peer-focus:text-sm 
                  peer-focus:text-blue-600 ${Icon ? 'left-10' : 'left-3'}`}
    >
      {label}
    </label>
  </div>
);

const SocialInput = ({ icon: Icon, label, ...props }) => (
  <div className="relative">
    {Icon && (
      <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
        <Icon className="h-5 w-5" />
      </div>
    )}
    <input
      className="w-full px-3 py-2 pl-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 peer placeholder-transparent"
      placeholder={label}
      {...props}
    />
    <label
      className="absolute left-10 -top-2.5 bg-white px-1 text-sm text-gray-600 transition-all 
                 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 
                 peer-placeholder-shown:top-2 peer-focus:-top-2.5 peer-focus:text-sm 
                 peer-focus:text-blue-600"
    >
      {label}
    </label>
  </div>
);

const MemberModal = ({ isOpen, onClose, member, onUpdate, onDelete }) => {

  const [personalInfo, setPersonalInfo] = useState(createPersonalInfo());
  const [email, setEmail] = useState('');
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [loading, setLoading] = useState(true);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const { showAlert } = useAlert();
  useEffect(() => {
    const fetchMemberData = async () => {
      if (isOpen && member && member.id) {
        setLoading(true);
        try {
          const fetchedMemberList = await memberService.getMembers({id: member.id});
          const fetchedMember = fetchedMemberList.members[0];
          const mergedPersonalInfo = mergePersonalInfo(createPersonalInfo(), fetchedMember.personalInfo || {});
          setPersonalInfo(mergedPersonalInfo);
          setEmail(fetchedMember.email || '');
        } catch (error) {
          console.error('Error fetching member data:', error);
          showAlert('error', 'Failed to fetch latest member data');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchMemberData();
  }, [isOpen, member, showAlert]);

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords(prev => ({ ...prev, [name]: value }));
  };

  const handlePersonalInfoChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfo(prevInfo => {
      if (name.includes('.')) {
        const [parent, child] = name.split('.');
        return {
          ...prevInfo,
          [parent]: {
            ...prevInfo[parent],
            [child]: value
          }
        };
      }
      return { ...prevInfo, [name]: value };
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await memberService.adminUpdateUserProfile(member.id, personalInfo);
      showAlert('success', 'Member updated successfully');
      onUpdate();
    } catch (err) {
      showAlert('error', 'Failed to update member');
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (passwords.newPassword !== passwords.confirmPassword) {
      showAlert('error', 'Passwords do not match');
      return;
    }
    setLoading(true);
    try {
      await memberService.updateMemberPassword(member.id, passwords.currentPassword, passwords.newPassword);
      showAlert('success', 'Password updated successfully');
      setPasswords({ currentPassword: '', newPassword: '', confirmPassword: '' });
    } catch (err) {
      showAlert('error', 'Failed to update password');
    } finally {
      setLoading(false);
    }
  };

 

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-full max-w-4xl shadow-lg rounded-md bg-white">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">Edit Member</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X size={24} />
          </button>
        </div>
        {loading ? (
          <div className="flex justify-center items-center h-64">Loading...</div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <form onSubmit={handleSubmit} className="space-y-4">
              <h4 className="text-xl font-semibold">Personal Information</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <InputField
                  icon={User}
                  type="text"
                  name="firstName"
                  value={personalInfo.firstName || ''}
                  onChange={handlePersonalInfoChange}
                  label="First Name"
                />
                <InputField
                  icon={User}
                  type="text"
                  name="lastName"
                  value={personalInfo.lastName || ''}
                  onChange={handlePersonalInfoChange}
                  label="Last Name"
                />
              </div>
              <InputField
                icon={Phone}
                type="tel"
                name="phone"
                value={personalInfo.phone || ''}
                onChange={handlePersonalInfoChange}
                label="Phone"
              />
              <InputField
                icon={Mail}
                type="email"
                value={email}
                label="Email"
                disabled
                className="bg-gray-100 cursor-not-allowed"
              />
              <InputField
                icon={Globe}
                type="text"
                name="country"
                value={personalInfo.country || ''}
                onChange={handlePersonalInfoChange}
                label="Country"
              />
              <InputField
                icon={MapPin}
                type="text"
                name="address.street"
                value={personalInfo.address?.street || ''}
                onChange={handlePersonalInfoChange}
                label="Street"
              />
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <InputField
                  type="text"
                  name="address.city"
                  value={personalInfo.address?.city || ''}
                  onChange={handlePersonalInfoChange}
                  label="City"
                />
                <InputField
                  type="text"
                  name="address.state"
                  value={personalInfo.address?.state || ''}
                  onChange={handlePersonalInfoChange}
                  label="State"
                />
                <InputField
                  type="text"
                  name="address.zipCode"
                  value={personalInfo.address?.zipCode || ''}
                  onChange={handlePersonalInfoChange}
                  label="Zip Code"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Bio</label>
                <textarea
                  name="bio"
                  value={personalInfo.bio || ''}
                  onChange={handlePersonalInfoChange}
                  rows="4"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Tell us about the member"
                ></textarea>
              </div>
              <div className="space-y-4">
                <h4 className="text-lg font-medium">Social Media</h4>
                <SocialInput
                  icon={Facebook}
                  type="text"
                  name="socials.facebook"
                  value={personalInfo.socials?.facebook || ''}
                  onChange={handlePersonalInfoChange}
                  label="Facebook URL"
                />
                <SocialInput
                  icon={Twitter}
                  type="text"
                  name="socials.x"
                  value={personalInfo.socials?.x || ''}
                  onChange={handlePersonalInfoChange}
                  label="X (Twitter) URL"
                />
                <SocialInput
                  icon={Linkedin}
                  type="text"
                  name="socials.linkedin"
                  value={personalInfo.socials?.linkedin || ''}
                  onChange={handlePersonalInfoChange}
                  label="LinkedIn URL"
                />
                <SocialInput
                  icon={Instagram}
                  type="text"
                  name="socials.instagram"
                  value={personalInfo.socials?.instagram || ''}
                  onChange={handlePersonalInfoChange}
                  label="Instagram URL"
                />
              </div>
              <button type="submit" className="w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300">
                Save Changes
              </button>
            </form>

            <div className="space-y-8">
              <div>
                <h4 className="text-xl font-semibold mb-4">Change Password</h4>
                <form onSubmit={handlePasswordSubmit} className="space-y-4">
                  <InputField
                    icon={Lock}
                    type="password"
                    name="currentPassword"
                    value={passwords.currentPassword}
                    onChange={handlePasswordChange}
                    label="Current Password"
                  />
                  <InputField
                    icon={Lock}
                    type="password"
                    name="newPassword"
                    value={passwords.newPassword}
                    onChange={handlePasswordChange}
                    label="New Password"
                  />
                  <InputField
                    icon={Lock}
                    type="password"
                    name="confirmPassword"
                    value={passwords.confirmPassword}
                    onChange={handlePasswordChange}
                    label="Confirm New Password"
                  />
                  <button type="submit" className="w-full bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-300">
                    Update Password
                  </button>
                </form>
              </div>
         
            </div>
          </div>
        )}
      </div>
     
    </div>
  );
};

export default MemberModal;