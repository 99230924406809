import React from 'react';
import { UserPlus, Users } from 'lucide-react';

const UserCreationButtons = ({ onCreateUser, onCreateTestUsers, showTestUsersButton }) => {
  return (
    <div className="mt-4 p-4 bg-gray-100 rounded-lg shadow-inner">
      <div className="flex flex-col sm:flex-row justify-end items-center space-y-2 sm:space-y-0 sm:space-x-4">
        <button
          onClick={onCreateUser}
          className="w-full sm:w-auto bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition duration-300 ease-in-out flex items-center justify-center"
        >
          <UserPlus size={20} className="mr-2" />
          Create User
        </button>
        {showTestUsersButton && (
          <button
            onClick={onCreateTestUsers}
            className="w-full sm:w-auto bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg transition duration-300 ease-in-out flex items-center justify-center"
          >
            <Users size={20} className="mr-2" />
            Create Test Users
          </button>
        )}
      </div>
    </div>
  );
};

export default UserCreationButtons;