import React, { useState, useEffect } from 'react';
import generalSettingsService from '../../../api/generalSettingsService';
import { Pencil, Trash2, Plus, X } from 'lucide-react';
import { useAlert } from '../../../hooks/useAlert';
import { useConfirmationModal } from '../../../hooks/useConfirmation';

export default function CycleManagement() {
  const [cycles, setCycles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState('add'); // 'add' or 'edit'
  const [currentCycle, setCurrentCycle] = useState({ name: '', periodType: 'DAY', periodValue: 1 });

  const { showAlert } = useAlert();
  const { openModal } = useConfirmationModal();

  useEffect(() => {
    fetchCycles();
  }, []);

  const fetchCycles = async () => {
    try {
      const fetchedCycles = await generalSettingsService.getCycles();
      setCycles(fetchedCycles);
    } catch (error) {
      showAlert('error', 'Failed to fetch cycles');
      console.error('Failed to fetch cycles:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedValue = name === 'periodValue' ? parseInt(value) : value;
    setCurrentCycle(prev => ({ ...prev, [name]: updatedValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (modalMode === 'edit') {
        await generalSettingsService.updateCycle(currentCycle.id, currentCycle);
        showAlert('success', 'Cycle updated successfully');
      } else {
        await generalSettingsService.createCycle(currentCycle);
        showAlert('success', 'Cycle created successfully');
      }
      fetchCycles();
      closeModal();
    } catch (error) {
      showAlert('error', 'Failed to save cycle');
      console.error('Failed to save cycle:', error);
    }
  };

  const openAddModal = () => {
    setModalMode('add');
    setCurrentCycle({ name: '', periodType: 'DAY', periodValue: 1 });
    setIsModalOpen(true);
  };

  const openEditModal = (cycle) => {
    setModalMode('edit');
    setCurrentCycle(cycle);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async (id, name) => {
    openModal(
      'Confirm Deletion',
      `Are you sure you want to delete the cycle "${name}"? This action cannot be undone.`,
      async () => {
        await generalSettingsService.deleteCycle(id);
        showAlert('success', 'Cycle deleted successfully');
        fetchCycles();
      }
    );
  };

  const formatPeriodDisplay = (cycle) => {
    return `Every ${cycle.periodValue} ${cycle.periodType.toLowerCase()}${cycle.periodValue > 1 ? 's' : ''}`;
  };

  return (
    <div className="bg-white shadow rounded-lg p-4 max-w-2xl mx-auto">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold">Manage Cycles</h3>
        <button
          onClick={openAddModal}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-300 flex items-center"
        >
          <Plus size={18} className="mr-2" />
          Add Cycle
        </button>
      </div>

      <div>
        <h4 className="text-lg font-semibold mb-2">Existing Cycles</h4>
        {cycles.length === 0 ? (
          <p className="text-gray-500 italic">No cycles found. Create a new one using the Add Cycle button.</p>
        ) : (
          <ul className="space-y-2">
            {cycles.map(cycle => (
              <li key={cycle.id} className="flex items-center justify-between bg-gray-100 p-2 rounded">
                <span>
                  {cycle.name} - {formatPeriodDisplay(cycle)}
                </span>
                <div>
                  <button 
                    onClick={() => openEditModal(cycle)} 
                    className="text-blue-500 hover:text-blue-700 mr-2 transition-colors duration-300"
                  >
                    <Pencil size={18} />
                  </button>
                  <button 
                    onClick={() => handleDelete(cycle.id, cycle.name)} 
                    className="text-red-500 hover:text-red-700 transition-colors duration-300"
                  >
                    <Trash2 size={18} />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">{modalMode === 'add' ? 'Add Cycle' : 'Edit Cycle'}</h3>
              <button onClick={closeModal} className="text-gray-500 hover:text-gray-700">
                <X size={24} />
              </button>
            </div>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Cycle Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={currentCycle.name}
                  onChange={handleInputChange}
                  placeholder="e.g., Monthly, Quarterly"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  required
                />
              </div>
              <div>
                <label htmlFor="periodType" className="block text-sm font-medium text-gray-700">Cycle Base Unit</label>
                <select
                  id="periodType"
                  name="periodType"
                  value={currentCycle.periodType}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="DAY">Day</option>
                  <option value="WEEK">Week</option>
                  <option value="MONTH">Month</option>
                </select>
              </div>
              <div>
                <label htmlFor="periodValue" className="block text-sm font-medium text-gray-700">
                  Cycle Duration (in {currentCycle.periodType.toLowerCase()}s)
                </label>
                <input
                  type="number"
                  id="periodValue"
                  name="periodValue"
                  value={currentCycle.periodValue}
                  onChange={handleInputChange}
                  min="1"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  required
                />
              </div>
              <div className="flex justify-end space-x-2">
                <button 
                  type="button" 
                  onClick={closeModal} 
                  className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 transition-colors duration-300"
                >
                  Cancel
                </button>
                <button 
                  type="submit" 
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-300"
                >
                  {modalMode === 'add' ? 'Add Cycle' : 'Update Cycle'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}