import React, { useState, useEffect } from 'react';
import { X, Check, AlertCircle } from 'lucide-react';
import memberService from '../../../api/memberService';
import payPlansService from '../../../api/payPlansService';
import { createPersonalInfo, validatePersonalInfo } from '../../../models/personalInfo';

const CreateMemberModal = ({ isOpen, onClose, onCreateMember }) => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    hostUsername: '',
    payPlanId: '',
    selectedCommissionId: '',
  });
  const [payPlans, setPayPlans] = useState([]);
  const [groupedCommissions, setGroupedCommissions] = useState([]);
  const [hostDetails, setHostDetails] = useState(null);
  const [error, setError] = useState('');
  const [isCheckingHost, setIsCheckingHost] = useState(false);

  useEffect(() => {
    if (isOpen) {
      fetchPublicPayPlans();
    }
  }, [isOpen]);

  const fetchPublicPayPlans = async () => {
    try {
      const plans = await payPlansService.getPublicPayPlans();
      setPayPlans(plans);
    } catch (error) {
      console.error('Error fetching pay plans:', error);
      setError('Failed to fetch pay plans. Please try again.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'payPlanId') {
      fetchGroupedCommissions(value);
    }
  };

  const handleHostBlur = async () => {
    const username = formData.hostUsername;
    if (!username) {
      setHostDetails(null);
      return;
    }
    setIsCheckingHost(true);
    try {
      const hostData = await memberService.getMemberByUsername(username);
      setHostDetails(hostData);
    } catch (error) {
      console.error('Error fetching host details:', error);
      setHostDetails(null);
    } finally {
      setIsCheckingHost(false);
    }
  };

  const fetchGroupedCommissions = async (payPlanId) => {
    try {
      const commissions = await payPlansService.getGroupedCommissions(payPlanId);
      setGroupedCommissions(commissions);
      if (commissions.length > 0) {
        setFormData(prev => ({ ...prev, selectedCommissionId: commissions[0].id.toString() }));
      } else {
        setFormData(prev => ({ ...prev, selectedCommissionId: '' }));
      }
    } catch (error) {
      console.error('Error fetching commissions:', error);
      setError('Failed to fetch commissions. Please try again.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const personalInfo = createPersonalInfo({
      firstName: formData.firstName,
      lastName: formData.lastName,
    });

    const personalInfoErrors = validatePersonalInfo(personalInfo);
    if (personalInfoErrors) {
      setError('Invalid personal information');
      console.error(personalInfoErrors);
      return;
    }

    const memberData = {
      ...formData,
      personalInfo,
      referredBy: formData.hostUsername
    };

    onCreateMember(memberData);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <div className="bg-white p-5 rounded-lg shadow-xl w-full max-w-4xl">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Create New Member</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="firstName">
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastName">
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="hostUsername">
              Host Username (optional)
            </label>
            <div className="relative">
              <input
                type="text"
                id="hostUsername"
                name="hostUsername"
                value={formData.hostUsername}
                onChange={handleChange}
                onBlur={handleHostBlur}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {isCheckingHost && (
                <div className="absolute right-0 top-0 mt-2 mr-2">
                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-blue-500"></div>
                </div>
              )}
              {!isCheckingHost && hostDetails && (
                <div className="absolute right-0 top-0 mt-2 mr-2 text-green-500">
                  <Check size={20} />
                </div>
              )}
              {!isCheckingHost && formData.hostUsername && !hostDetails && (
                <div className="absolute right-0 top-0 mt-2 mr-2 text-red-500">
                  <AlertCircle size={20} />
                </div>
              )}
            </div>
            {hostDetails && (
              <p className="text-sm text-gray-600 mt-1">
                Host: {hostDetails.username} (ID: {hostDetails.id})
              </p>
            )}
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="payPlanId">
              Pay Plan
            </label>
            <select
              id="payPlanId"
              name="payPlanId"
              value={formData.payPlanId}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              <option value="">Select a pay plan</option>
              {payPlans.map(plan => (
                <option key={plan.id} value={plan.id}>{plan.name}</option>
              ))}
            </select>
          </div>
          {groupedCommissions.length > 0 && (
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="selectedCommissionId">
                Commission
              </label>
              <select
                id="selectedCommissionId"
                name="selectedCommissionId"
                value={formData.selectedCommissionId}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              >
                {groupedCommissions.map(commission => (
                  <option key={commission.id} value={commission.id}>{commission.name}</option>
                ))}
              </select>
            </div>
          )}
          {error && <p className="text-red-500 text-xs italic col-span-2">{error}</p>}
          <div className="col-span-2 flex items-center justify-end mt-4">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Create Member
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateMemberModal;