import React, { useState, useEffect } from 'react';
import { Search, ArrowUpCircle, ArrowDownCircle, Filter } from 'lucide-react';
import transactionsService from '../../api/transactionsService';
import { useAlert } from '../../hooks/useAlert';

const ManageTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    status: '',
    memberUsername: '',
    payerUsername: '',
    dateFrom: '',
    dateTo: '',
  });
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
    hasNextPage: false,
    hasPreviousPage: false
  });

  const { showAlert } = useAlert();

  useEffect(() => {
    fetchTransactions();
  }, [pagination.pageNumber]);

  const fetchTransactions = async () => {
    setIsLoading(true);
    try {
      const data = await transactionsService.getAllEarnings({
        ...filters,
        page: pagination.pageNumber,
        limit: pagination.pageSize,
      });
      setTransactions(data.earnings);
      setPagination(data.metadata);
    } catch (err) {
      setError(err.message || 'An error occurred while fetching transactions');
      showAlert('error', 'Failed to fetch transactions');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setPagination(prev => ({ ...prev, pageNumber: 1 }));
    fetchTransactions();
  };

  const handlePageChange = (newPage) => {
    setPagination(prev => ({ ...prev, pageNumber: newPage }));
  };

  const getStatusBadgeClass = (status) => {
    const classes = {
      SCHEDULED: 'bg-yellow-100 text-yellow-800',
      AVAILABLE: 'bg-green-100 text-green-800',
      WITHDRAWN: 'bg-blue-100 text-blue-800',
      PAID: 'bg-purple-100 text-purple-800',
      CANCELLED: 'bg-red-100 text-red-800'
    };
    return classes[status] || 'bg-gray-100 text-gray-800';
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-64">
      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
    </div>;
  }

  if (error) {
    return <div className="text-center text-red-500 p-4 bg-red-100 rounded-lg">{error}</div>;
  }

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="px-6 py-2 bg-gradient-to-r from-blue-500 to-indigo-600">
        <h2 className="text-2xl font-bold text-white">Manage Transactions</h2>
      </div>

      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 p-6">
        <div className="bg-green-50 p-4 rounded-lg shadow">
          <h3 className="text-green-700 text-lg font-semibold">Available</h3>
          <p className="text-2xl font-bold text-green-800">
            {transactionsService.formatAmount(
              transactions.reduce((sum, t) => 
                t.status === 'AVAILABLE' ? sum + t.amount : sum, 0)
            )}
          </p>
        </div>
        <div className="bg-blue-50 p-4 rounded-lg shadow">
          <h3 className="text-blue-700 text-lg font-semibold">Withdrawn</h3>
          <p className="text-2xl font-bold text-blue-800">
            {transactionsService.formatAmount(
              transactions.reduce((sum, t) => 
                t.status === 'WITHDRAWN' ? sum + t.amount : sum, 0)
            )}
          </p>
        </div>
        <div className="bg-yellow-50 p-4 rounded-lg shadow">
          <h3 className="text-yellow-700 text-lg font-semibold">Scheduled</h3>
          <p className="text-2xl font-bold text-yellow-800">
            {transactionsService.formatAmount(
              transactions.reduce((sum, t) => 
                t.status === 'SCHEDULED' ? sum + t.amount : sum, 0)
            )}
          </p>
        </div>
        <div className="bg-purple-50 p-4 rounded-lg shadow">
          <h3 className="text-purple-700 text-lg font-semibold">Total Paid</h3>
          <p className="text-2xl font-bold text-purple-800">
            {transactionsService.formatAmount(
              transactions.reduce((sum, t) => 
                t.status === 'PAID' ? sum + t.amount : sum, 0)
            )}
          </p>
        </div>
      </div>

      {/* Filters */}
      <form onSubmit={handleSearch} className="p-6 bg-gray-50 border-b border-gray-200">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <input
              type="text"
              name="memberUsername"
              placeholder="Filter by receiver"
              value={filters.memberUsername}
              onChange={handleFilterChange}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <input
              type="text"
              name="payerUsername"
              placeholder="Filter by payer"
              value={filters.payerUsername}
              onChange={handleFilterChange}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <select
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">All Statuses</option>
              <option value="SCHEDULED">Scheduled</option>
              <option value="AVAILABLE">Available</option>
              <option value="WITHDRAWN">Withdrawn</option>
              <option value="PAID">Paid</option>
              <option value="CANCELLED">Cancelled</option>
            </select>
          </div>
          <div>
            <input
              type="date"
              name="dateFrom"
              value={filters.dateFrom}
              onChange={handleFilterChange}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <input
              type="date"
              name="dateTo"
              value={filters.dateTo}
              onChange={handleFilterChange}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="flex items-center">
            <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg transition duration-300 ease-in-out flex items-center justify-center">
              <Search size={20} className="mr-2" />
              Search
            </button>
          </div>
        </div>
      </form>

      {/* Transactions Table */}
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Receiver</th>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Payer</th>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount</th>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Commission</th>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Level</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {transactions.map((transaction) => (
              <tr key={transaction.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {transactionsService.formatDate(transaction.scheduledDate)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <ArrowDownCircle size={16} className="text-green-500 mr-2" />
                    <div>
                      <div className="text-sm font-medium text-gray-900">{transaction.member.username}</div>
                      <div className="text-sm text-gray-500">{transaction.member.email}</div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <ArrowUpCircle size={16} className="text-red-500 mr-2" />
                    <div>
                      <div className="text-sm font-medium text-gray-900">{transaction.payer.username}</div>
                      <div className="text-sm text-gray-500">{transaction.payer.email}</div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {transactionsService.formatAmount(transaction.amount)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {transaction.commission.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeClass(transaction.status)}`}>
                    {transaction.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {transaction.level !== null ? `Level ${transaction.level}` : 'Self'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="bg-gray-50 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{(pagination.pageNumber - 1) * pagination.pageSize + 1}</span> to{' '}
              <span className="font-medium">{Math.min(pagination.pageNumber * pagination.pageSize, pagination.totalCount)}</span> of{' '}
              <span className="font-medium">{pagination.totalCount}</span> results
            </p>
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <button
                onClick={() => handlePageChange(pagination.pageNumber - 1)}
                disabled={!pagination.hasPreviousPage}
                className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Previous
              </button>
              <button
                onClick={() => handlePageChange(pagination.pageNumber + 1)}
                disabled={!pagination.hasNextPage}
                className="relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageTransactions;