import React, { useState, useEffect } from 'react';
import { User, Phone, MapPin, Facebook, Twitter, Linkedin, Instagram, Lock, Mail, Globe } from 'lucide-react';
import memberService from '../../api/memberService';
import { useAlert } from '../../hooks/useAlert';
import { useSelector } from 'react-redux';
import { createPersonalInfo, mergePersonalInfo, updatePersonalInfo } from '../../models/personalInfo';



const InputField = ({ icon: Icon, ...props }) => (
  <div className="relative">
    {Icon && (
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <Icon className="h-5 w-5 text-gray-400" />
      </div>
    )}
    <input 
      className={`w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${Icon ? 'pl-10' : ''}`} 
      {...props} 
    />
  </div>
);

const SocialInput = ({ icon: Icon, ...props }) => (
  <div className="flex items-center space-x-2">
    {Icon && <Icon className="h-5 w-5 text-gray-400" />}
    <input 
      className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
      {...props} 
    />
  </div>
);

function Profile() {
  const [personalInfo, setPersonalInfo] = useState(createPersonalInfo());

  const [email, setEmail] = useState('');
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [loading, setLoading] = useState(true);
  const { member } = useSelector((state) => state.auth);

  const {showAlert} = useAlert();

  useEffect(() => {
    fetchProfile();
  }, []);
  const fetchProfile = async () => {
    try {
      setLoading(true);
      const profile =  await memberService.getCurrentUserProfile();
      const mergedPersonalInfo = mergePersonalInfo(personalInfo, profile.personalInfo);
      setPersonalInfo(mergedPersonalInfo);
      setEmail(profile.email);
    } catch (err) {
      showAlert('error', 'Failed to fetch profile');
    } finally {
      setLoading(false);
    }
  };
  const handlePersonalInfoChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfo(prevInfo => {
      // Handle nested objects (e.g., address, socials)
      if (name.includes('.')) {
        const [parent, child] = name.split('.');
        return {
          ...prevInfo,
          [parent]: {
            ...prevInfo[parent],
            [child]: value
          }
        };
      }
      // Handle top-level properties
      return { ...prevInfo, [name]: value };
    });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    try {
      await memberService.updatePersonalInfo(personalInfo);
      showAlert ('success',  'Profile updated successfully' );
    } catch (err) {
      showAlert ('error',  'Failed to update profile' );
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    if (passwords.newPassword !== passwords.confirmPassword) {
      showAlert ('error',  'Passwords do not match' );
      return;
    }

    try {
      await memberService.updatePassword(passwords.currentPassword, passwords.newPassword);
      showAlert ('success',  'Password updated successfully' );
      setPasswords({ currentPassword: '', newPassword: '', confirmPassword: '' });
    } catch (err) {
      showAlert ('error',  'Failed to update password' );
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Your Profile</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-semibold mb-6">Personal Information</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <InputField
                icon={User}
                type="text"
                name="firstName"
                value={personalInfo.firstName}
                onChange={handlePersonalInfoChange}
                placeholder="First Name"
              />
              <InputField
                icon={User}
                type="text"
                name="lastName"
                value={personalInfo.lastName}
                onChange={handlePersonalInfoChange}
                placeholder="Last Name"
              />
            </div>
            <InputField
              icon={Phone}
              type="tel"
              name="phone"
              value={personalInfo.phone}
              onChange={handlePersonalInfoChange}
              placeholder="Phone"
            />
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Mail className="h-5 w-5 text-gray-400" />
              </div>
              <input 
                type="email"
                value={email}
                className="w-full pl-10 px-3 py-2 border border-gray-300 rounded-md bg-gray-100 text-gray-600 cursor-not-allowed"
                placeholder="Email"
                disabled
              />
            </div>
            <p className="text-sm text-gray-500 mt-1">Email cannot be changed here. Contact support for email updates.</p>
            <InputField
              icon={Globe}
              type="text"
              name="country"
              value={personalInfo.country}
              onChange={handlePersonalInfoChange}
              placeholder="Country"
            />
            <div className="space-y-4">
              <InputField
                icon={MapPin}
                type="text"
                name="address.street"
                value={personalInfo.address.street}
                onChange={handlePersonalInfoChange}
                placeholder="Street"
              />
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <InputField
                  type="text"
                  name="address.city"
                  value={personalInfo.address.city}
                  onChange={handlePersonalInfoChange}
                  placeholder="City"
                />
                <InputField
                  type="text"
                  name="address.state"
                  value={personalInfo.address.state}
                  onChange={handlePersonalInfoChange}
                  placeholder="State"
                />
                <InputField
                  type="text"
                  name="address.zipCode"
                  value={personalInfo.address.zipCode}
                  onChange={handlePersonalInfoChange}
                  placeholder="Zip Code"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Bio</label>
              <textarea
                name="bio"
                value={personalInfo.bio}
                onChange={handlePersonalInfoChange}
                rows="4"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Tell us about yourself"
              ></textarea>
            </div>
            <div className="space-y-4">
              <h3 className="text-lg font-medium">Social Media</h3>
              <SocialInput
                icon={Facebook}
                type="text"
                name="socials.facebook"
                value={personalInfo.socials.facebook}
                onChange={handlePersonalInfoChange}
                placeholder="Facebook URL"
              />
              <SocialInput
                icon={Twitter}
                type="text"
                name="socials.x"
                value={personalInfo.socials.x}
                onChange={handlePersonalInfoChange}
                placeholder="X (Twitter) URL"
              />
              <SocialInput
                icon={Linkedin}
                type="text"
                name="socials.linkedin"
                value={personalInfo.socials.linkedin}
                onChange={handlePersonalInfoChange}
                placeholder="LinkedIn URL"
              />
              <SocialInput
                icon={Instagram}
                type="text"
                name="socials.instagram"
                value={personalInfo.socials.instagram}
                onChange={handlePersonalInfoChange}
                placeholder="Instagram URL"
              />
            </div>
            <button type="submit" className="w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300">
              Save Changes
            </button>
          </form>
        </div>

        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-semibold mb-6">Change Password</h2>
          <form onSubmit={handlePasswordSubmit} className="space-y-4">
            <InputField
              icon={Lock}
              type="password"
              name="currentPassword"
              value={passwords.currentPassword}
              onChange={handlePasswordChange}
              placeholder="Current Password"
            />
            <InputField
              icon={Lock}
              type="password"
              name="newPassword"
              value={passwords.newPassword}
              onChange={handlePasswordChange}
              placeholder="New Password"
            />
            <InputField
              icon={Lock}
              type="password"
              name="confirmPassword"
              value={passwords.confirmPassword}
              onChange={handlePasswordChange}
              placeholder="Confirm New Password"
            />
            <button type="submit" className="w-full bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-300">
              Update Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Profile;