import React, { useEffect, useState } from 'react';
import { X } from 'lucide-react';

const Toast = ({ type, message, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    const timer = setTimeout(() => {
      setIsVisible(false);
      setTimeout(onClose, 300); // Wait for fade out animation before calling onClose
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  const bgColor = type === 'success' ? 'bg-green-500' : 'bg-red-500';

  return (
    <div
      className={`fixed top-4 right-4 z-50 ${bgColor} bg-opacity-50 backdrop-blur-sm text-white px-6 py-4 rounded-lg shadow-lg flex items-center transition-all duration-300 ease-in-out ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-[-20px]'
      }`}
      style={{ minWidth: '300px', maxWidth: '80vw' }}
    >
      <span className="flex-grow text-lg">{message}</span>
      <button
        onClick={() => setIsVisible(false)}
        className="ml-4 text-white hover:text-gray-200 transition-colors duration-200"
      >
        <X size={24} />
      </button>
    </div>
  );
};

export default Toast;