import {axiosInstance} from './config/axiosConfig';
import {handleError} from '../utils/errorHandler';


const generalSettingsService = {
  // Cycle-related functions
  getCycles: async () => {
    try {
      const response = await axiosInstance.get('/cycles');
      return response.data;
    } catch (error) {
      handleError(error, 'fetching cycles');
    }
  },

  getCycleById: async (id) => {
    try {
      const response = await axiosInstance.get(`/cycles/${id}`
      );
      return response.data;
    } catch (error) {
      handleError(error, 'fetching cycle');
    }
  },

  createCycle: async (cycleData) => {
    try {
      const response = await axiosInstance.post('/cycles', cycleData, 
        { useAdminToken: true }
      );
      return response.data;
    } catch (error) {
      handleError(error, 'creating cycle');
    }
  },

  updateCycle: async (id, cycleData) => {
    try {
      const response = await axiosInstance.put(`/cycles/${id}`, cycleData, 
        { useAdminToken: true });
      return response.data;
    } catch (error) {
      handleError(error, 'updating cycle');
    }
  },

  deleteCycle: async (id) => {
    try {
      const response = await axiosInstance.delete(`/cycles/${id}`, 
        { useAdminToken: true }
      );
      return response.data;
    } catch (error) {
      handleError(error, 'deleting cycle');
    }
  },

  getSettings: async () => {
    try {
      const response = await axiosInstance.get('/settings');
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  
  // Update settings (admin)
  updateSettings: async (settings) => {
    try {
      const response = await axiosInstance.put('/settings/admin', settings, 
        { useAdminToken: true });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

export default generalSettingsService;