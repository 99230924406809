import React, { useState, useEffect } from 'react';
import { AlertTriangle, Copy, Check, Loader } from 'lucide-react';
import reportPaymentsService from '../../api/reportPaymentsService';


const WalletDisplay = ({ address, label, network }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(address);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="mb-4 p-4 border rounded bg-gray-50">
      <div className="font-semibold text-gray-700 mb-2">{network}</div>
      <div className="text-sm text-gray-600 mb-1">{label}</div>
      <div className="flex items-center gap-2">
        <code className="flex-1 bg-white p-2 rounded text-sm font-mono break-all">
          {address}
        </code>
        <button
          onClick={handleCopy}
          className="p-2 hover:bg-gray-200 rounded transition-colors"
        >
          {copied ? <Check size={20} className="text-green-500" /> : <Copy size={20} />}
        </button>
      </div>
    </div>
  );
};

const ReportPaymentForm = ({ userPlanRegFee = 0, onSuccess }) => {
  const [formData, setFormData] = useState({
    amount: userPlanRegFee,
    txHash: '',
    paymentType: 'USDT',
    message: '',
    screenshot: null
  });

  const [loading, setLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    setError(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file size (5MB limit)
      if (file.size > 5 * 1024 * 1024) {
        setError('File size must be less than 5MB');
        return;
      }

      // Validate file type
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!allowedTypes.includes(file.type)) {
        setError('Only JPEG, PNG and GIF files are allowed');
        return;
      }

      setFormData(prev => ({
        ...prev,
        screenshot: file
      }));
      setPreviewUrl(URL.createObjectURL(file));
      setError(null);
    }
  };

  const validateForm = () => {
    if (!formData.txHash) {
      setError('Transaction hash is required');
      return false;
    }

    if (!formData.screenshot) {
      setError('Payment screenshot is required');
      return false;
    }

    if (formData.amount !== userPlanRegFee) {
      setError('Amount must match the registration fee');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await reportPaymentsService.reportPayment(formData);
      
      if (response.success) {
        setSuccess(true);
        // Clear form
        setFormData({
          amount: userPlanRegFee,
          txHash: '',
          paymentType: 'USDT',
          message: '',
          screenshot: null
        });
        setPreviewUrl('');
        
        // Notify parent component
        if (onSuccess) {
          onSuccess(response.data);
        }
      } else {
        setError(response.message || 'Failed to report payment');
      }
    } catch (err) {
      try {
        const errResponse = JSON.parse(err.request.response);
        setError(errResponse.message || err.message || 'An error occurred while reporting payment');
      } catch (err) {
        setError(err.message || 'An error occurred while reporting payment');
      } 
      
    } finally {
      setLoading(false);
    }
  };

  // Cleanup preview URL when component unmounts
  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  return (
    <div className="flex flex-wrap lg:flex-nowrap gap-8 max-w-6xl mx-auto p-6">
      {/* Left Section - Wallet Information */}
      <div className="w-full lg:w-2/5">
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Payment Addresses</h2>
          
          <div className="mb-4 p-3 bg-yellow-50 border border-yellow-200 rounded flex items-start gap-2">
            <AlertTriangle className="text-yellow-500 mt-1" size={20} />
            <p className="text-sm text-yellow-700">
              Please double-check the address before making any payments. We are not responsible for funds sent to incorrect addresses.
            </p>
          </div>

          <WalletDisplay
            network="USDT (TRC20)"
            label="USDT Wallet Address"
            address="TVBN21mpCtFUypvrbQ4RDEy2YLJ8CyhSXX"
          />

          <WalletDisplay
            network="Bitcoin (BTC)"
            label="BTC Wallet Address"
            address="1Hpasec4vtiy6iy2teGn5Wmm4zvMYcDhu2"
          />
        </div>
      </div>

      {/* Right Section - Report Payment Form */}
      <div className="w-full lg:w-3/5">
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Report Your Payment</h2>
          
          {error && (
            <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded flex items-start gap-2">
              <AlertTriangle className="text-red-500 mt-1" size={20} />
              <p className="text-sm text-red-700">{error}</p>
            </div>
          )}

          {success && (
            <div className="mb-4 p-3 bg-green-50 border border-green-200 rounded flex items-start gap-2">
              <Check className="text-green-500 mt-1" size={20} />
              <p className="text-sm text-green-700">Payment reported successfully! Our team will verify your payment shortly.</p>
            </div>
          )}
          
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Amount
              </label>
              <input
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleInputChange}
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
                step="0.01"
                readOnly
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Transaction Hash
              </label>
              <input
                type="text"
                name="txHash"
                value={formData.txHash}
                onChange={handleInputChange}
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
                placeholder="Enter your transaction hash"
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Payment Type
              </label>
              <select
                name="paymentType"
                value={formData.paymentType}
                onChange={handleInputChange}
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="USDT">USDT (TRC20)</option>
                <option value="BTC">Bitcoin (BTC)</option>
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Message
              </label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                rows="3"
                placeholder="Add any additional information about your payment"
              />
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Payment Screenshot
              </label>
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded">
                <div className="space-y-1 text-center">
                  {previewUrl ? (
                    <div className="mb-3">
                      <img
                        src={previewUrl}
                        alt="Screenshot preview"
                        className="mx-auto max-h-48 object-contain"
                      />
                      <button
                        type="button"
                        onClick={() => {
                          setPreviewUrl('');
                          setFormData(prev => ({ ...prev, screenshot: null }));
                        }}
                        className="mt-2 text-sm text-red-600 hover:text-red-500"
                      >
                        Remove
                      </button>
                    </div>
                  ) : (
                    <div className="flex text-sm text-gray-600">
                      <label className="relative cursor-pointer bg-white rounded font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none">
                        <span>Upload a file</span>
                        <input
                          type="file"
                          name="screenshot"
                          className="sr-only"
                          accept="image/*"
                          onChange={handleFileChange}
                          required
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                  )}
                  <p className="text-xs text-gray-500">
                    PNG, JPG, GIF up to 5MB
                  </p>
                </div>
              </div>
            </div>

            <button
              type="submit"
              disabled={loading}
              className={`w-full py-2 px-4 border border-transparent rounded font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {loading ? (
                <span className="flex items-center justify-center">
                  <Loader className="animate-spin -ml-1 mr-3 h-5 w-5" />
                  Submitting...
                </span>
              ) : (
                'Submit Payment Report'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReportPaymentForm;