import React from 'react';
import { Link } from 'react-router-dom';
import { Users, Home, DollarSign, GitBranch, TicketIcon, Settings, FileText, LogOut, ChevronLeft, ChevronRight } from 'lucide-react';

function AdminSidebar({ isSidebarOpen, toggleSidebar, handleLogout }) {
  const sidebarItems = [
    { to: "dashboard", icon: Home, text: "Dashboard" },
    { to: "manage-members", icon: Users, text: "Manage Members" },
    { to: "transactions", icon: DollarSign, text: "Transactions" },
    { to: "trees", icon: GitBranch, text: "Trees" },
    { to: "tickets", icon: TicketIcon, text: "Tickets" },
    { to: "general-settings", icon: Settings, text: "General Settings" },
    { to: "payplans", icon: FileText, text: "Payplans" },
  ];

  return (
    <div 
      className={`bg-blue-600 text-white ${
        isSidebarOpen ? 'w-64' : 'w-20'
      } flex flex-col justify-between transition-all duration-300 ease-in-out`}
    >
      <div>
        <div className="flex items-center justify-between p-4">
          {isSidebarOpen && <h2 className="text-2xl font-semibold">Admin</h2>}
          <button onClick={toggleSidebar} className="p-2 rounded-full hover:bg-blue-700 focus:outline-none">
            {isSidebarOpen ? <ChevronLeft size={24} /> : <ChevronRight size={24} />}
          </button>
        </div>
        <nav className="mt-8">
          {sidebarItems.map((item, index) => (
            <Link 
              key={index}
              to={item.to} 
              className={`flex items-center py-2 px-4 rounded transition duration-200 hover:bg-blue-700 ${
                isSidebarOpen ? '' : 'justify-center'
              }`}
            >
              <item.icon size={20} />
              {isSidebarOpen && <span className="ml-4">{item.text}</span>}
            </Link>
          ))}
        </nav>
      </div>
      <button 
        onClick={handleLogout} 
        className={`flex items-center py-2 px-4 rounded transition duration-200 hover:bg-blue-700 mb-8 ${
          isSidebarOpen ? '' : 'justify-center'
        }`}
      >
        <LogOut size={20} />
        {isSidebarOpen && <span className="ml-4">Logout</span>}
      </button>
    </div>
  );
}

export default AdminSidebar;