// src/api/payPlansService.js
import {axiosInstance} from './config/axiosConfig';
import {handleError} from '../utils/errorHandler';



let cachedPayPlans = null;

const payPlansService = {
  // Pay Plans CRUD operations
  getPayPlans: async () => {
    try {
      const response = await axiosInstance.get('/payplans', { useAdminToken: true });
      return response.data;
    } catch (error) {
      return handleError(error, 'fetching pay plans');
    }
  },

  createPayPlan: async (payPlanData) => {
    try {
      const response = await axiosInstance.post('/payplans', payPlanData, { 
        useAdminToken: true 
      });
      return response.data;
    } catch (error) {
      return handleError(error, 'creating pay plan');
    }
  },

  updatePayPlan: async (id, payPlanData) => {
    try {
      const response = await axiosInstance.put(`/payplans/${id}`, payPlanData, {
        useAdminToken: true
      });
      return response.data;
    } catch (error) {
      return handleError(error, 'updating pay plan');
    }
  },

  deletePayPlan: async (id) => {
    try {
      const response = await axiosInstance.delete(`/payplans/${id}`, {
        useAdminToken: true
      });
      return response.data;
    } catch (error) {
      return handleError(error, 'deleting pay plan');
    }
  },

  // Commission operations
  getCommissions: async (payPlanId) => {
    try {
      const response = await axiosInstance.get(`/payplans/${payPlanId}/commissions`, {
        useAdminToken: true
      });
      return response.data;
    } catch (error) {
      return handleError(error, 'fetching commissions');
    }
  },

  createCommission: async (payPlanId, commissionData) => {
    try {
      const response = await axiosInstance.post(
        `/payplans/${payPlanId}/commissions`, 
        commissionData,
        { useAdminToken: true }
      );
      return response.data;
    } catch (error) {
      return handleError(error, 'creating commission');
    }
  },

  updateCommission: async (payPlanId, commissionId, commissionData) => {
    try {
      const response = await axiosInstance.put(
        `/payplans/${payPlanId}/commissions/${commissionId}`, 
        commissionData,
        { useAdminToken: true }
      );
      return response.data;
    } catch (error) {
      return handleError(error, 'updating commission');
    }
  },

  deleteCommission: async (payPlanId, commissionId) => {
    try {
      const response = await axiosInstance.delete(
        `/payplans/${payPlanId}/commissions/${commissionId}`,
        { useAdminToken: true }
      );
      return response.data;
    } catch (error) {
      return handleError(error, 'deleting commission');
    }
  },

  // Public endpoints
  getPublicPayPlans: async () => {
    try {
      if (cachedPayPlans) {
        return cachedPayPlans;
      }
      const response = await axiosInstance.get('/payplans/public');
      cachedPayPlans = response.data;
      return cachedPayPlans;
    } catch (error) {
      return handleError(error, 'fetching public pay plans');
    }
  },

  getGroupedCommissions: async (payPlanId) => {
    try {
      const payPlans = await payPlansService.getPublicPayPlans();
      const selectedPlan = payPlans.find(plan => plan.id === parseInt(payPlanId));
      return selectedPlan ? selectedPlan.commissions : [];
    } catch (error) {
      return handleError(error, 'fetching grouped commissions');
    }
  },

  // Cache management
  clearCache: () => {
    cachedPayPlans = null;
  }
};

export default payPlansService;